@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  font-family: "Inter", sans-serif;
}

/* 3D Carousel Styles */
.perspective-1000 {
    perspective: 1000px;
}

.transform-style-3d {
    transform-style: preserve-3d;
}

/* Project Carousel Styles */
.project-carousel-container {
  position: relative;
  width: 100%;
  min-height: 400px;
  perspective: 3000px;
  overflow: visible;
}

@media (min-width: 768px) {
  .project-carousel-container {
    height: 600px;
  }
}

.project-carousel {
  position: absolute;
  width: 300px;
  height: 200px;
  top: 30%;
  left: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) perspective(3000px) rotateX(-25deg);
  will-change: transform;
}

.carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-style: preserve-3d;
  transform-origin: center;
  transition: transform 0.5s ease-in-out;
  will-change: transform;
}

.project-card {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  cursor: pointer;
  /* backface-visibility: hidden; */
}

@keyframes autoRotate {
  from {
    transform: translate(-50%, -50%) perspective(2000px) rotateX(-15deg) rotateY(0deg);
  }
  to {
    transform: translate(-50%, -50%) perspective(2000px) rotateX(-15deg) rotateY(-360deg);
  }
}

.auto-rotating {
  animation: autoRotate 20s linear infinite;
}

.auto-rotating.paused {
  animation-play-state: paused;
}